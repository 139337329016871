.html {
  scroll-behavior: smooth;
}

body {
  font-family: Montserrat, sans-serif;
}

.profileCard {
  text-align: center;
  height: 500px;
}

.section-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card .card-content p {
  margin: 0;
  padding: 10px;
  text-indent: -0.5em;
}

.card-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  max-width: 12rem;
  max-height: 12rem;
  border-radius: 50%;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2);
}

.card-img {
  margin: 2rem;
  max-width: 10rem;
  height: auto;
}

/* Appears only for screen sizes 700px and above basically Desktop */
@media screen and (min-width: 700px) {
  .pos-rel-l6 {
    position: relative;
    left: 30%;
  }
}

/* Appears only for screen sizes 700px and below basically Mobile */
@media screen and (max-width: 700px) {
  .card-circle {
    width: 30vw;
    height: 30vw;
  }

  .card-img {
    margin: 3vw;
    width: 20vw;
    height: auto;
  }
}
